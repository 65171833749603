import type { Palette } from "../../../config/theme";

import type { UseMenuPages_rootPages } from "../hooks/types/UseMenuPages";
import type { UsePageForNavigation_page_childPages } from "../hooks/types/UsePageForNavigation";
import type { InternalNavItem } from "../types";
import { PageTemplate } from "../../../types/global-types";

const makeNavItemFromPage = (
  page: UseMenuPages_rootPages | UsePageForNavigation_page_childPages,
): InternalNavItem => ({
  title: page.navigationTitle,
  href: `/?path=${page.path}`,
  path: page.path,
  slug: page.slug,
  hasChildren:
    page.pageTemplate === PageTemplate.STORES
      ? true
      : page.childPages.length > 0,
  palette: (page as UseMenuPages_rootPages).palette as Palette,
  type: "internal",
});

export default makeNavItemFromPage;
