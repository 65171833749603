import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { PageBaseParts } from "../queries";
import type { UseMenuPages, UseMenuPagesVariables } from "./types/UseMenuPages";

export const GET_MENU_PAGES = gql`
  query UseMenuPages($menuId: MenuId) {
    rootPages(menuId: $menuId) {
      ...PageBaseParts
      childPages {
        ...PageBaseParts
      }
    }
  }
  ${PageBaseParts}
`;

const useMenuPages = ({ menuId }: UseMenuPagesVariables) => {
  const { data, previousData, error, loading } = useQuery<UseMenuPages, {}>(
    GET_MENU_PAGES,
    {
      variables: { menuId },
      skip: !menuId,
    },
  );

  const pages = (data ?? previousData)?.rootPages;

  return { pages, error, loading };
};

export default useMenuPages;
