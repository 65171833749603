import { useCallback } from "react";
import { TransOrg } from "../config/i18n";
import { useTranslationOrg } from "../config/i18n";
import { useStoreState } from "../lib/model";
import type { Trans as TransType } from "next-i18next";
import type { TransProps } from "react-i18next";

const AdminLink = ({ fullKey }: { fullKey: string }) => {
  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        window.open(`/admin#/Translation/${fullKey}`);
      }}
      style={{ color: "red", pointerEvents: "all" }}
      target="_blank"
      href={`/admin#/Translation/${fullKey}`}
      rel="noreferrer"
    >
      {fullKey}
    </a>
  );
};
export const useTranslation = (namespace: string = "common") => {
  const showKey = useStoreState((s) => s.profile.user.showI18nKeys);

  const { t: tOrg, ...rest } = useTranslationOrg(namespace);

  const t = useCallback(
    (key: string | string[], ...trest: any[]) => {
      if (!showKey) {
        return tOrg(key, ...trest);
      }
      // if rare case of multiple keys, show first
      const fullKey = Array.isArray(key)
        ? `${namespace}.${key[0]}`
        : `${namespace}.${key}`;
      const element = <AdminLink fullKey={fullKey} />;
      // we override "toString" in case that this is used as a string
      const manipulated = Object.assign(
        {},
        {
          toString: () => fullKey,
          split: (separator: string) => fullKey.split(separator),
        },
        element,
      );
      return manipulated;
    },
    [tOrg, showKey, namespace],
  );
  return {
    t,
    ...rest,
  };
};

export const Trans = (props: TransProps<any>) => {
  const showKey = useStoreState((s) => s.profile.user.showI18nKeys);

  if (showKey) {
    const fullKey = Array.isArray(props.i18nKey)
      ? `${props.ns}.${props.i18nKey[0]}`
      : `${props.ns}.${props.i18nKey}`;

    return <AdminLink fullKey={fullKey} />;
  }
  return <TransOrg {...props} />;
};
