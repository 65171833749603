import { BRAND_ASSORTMENT_ID } from "../../../config/constants";
import {
  BRAND_BASE_PATH,
  CATEGORY_PAGE_PATH,
  SHOP_PATH,
} from "../../../config/locations";
import type { GetTopBrands_topBrands_assortment } from "../../categories/hooks/types/GetTopBrands";
import type { InternalNavItem } from "../types";

const isBrandAssortment = (assortment: { _id: string }) =>
  assortment._id === BRAND_ASSORTMENT_ID;

export const makeHref = (assortmentId: string) =>
  `${CATEGORY_PAGE_PATH}?assortmentId=${encodeURIComponent(assortmentId)}`;
interface Assortment {
  _id: string;
  texts: {
    title: string;
    slug: string;
  };
  childrenCount?: number;
}
const makeBaseNavItem = (
  assortment: Assortment,
  additionalQuery?: string,
): Pick<InternalNavItem, "title" | "slug" | "href" | "type" | "path"> => ({
  title: assortment.texts?.title,
  slug: assortment.texts?.slug,
  href:
    makeHref(assortment._id) + (additionalQuery ? `&${additionalQuery}` : ""),
  type: "internal",
  path: `${SHOP_PATH}${assortment.texts?.slug}${
    additionalQuery ? `?${additionalQuery}` : ""
  }`,
});

const makeNavItemFromAssortment = (
  assortment: Assortment,
  additionalQuery?: string,
) => {
  return {
    ...makeBaseNavItem(assortment, additionalQuery),
    hasChildren: isBrandAssortment(assortment)
      ? false
      : assortment.childrenCount > 0,
  };
};

export default makeNavItemFromAssortment;
export const makeNavItemForChildAssortment = (assortment: Assortment) => {
  return {
    ...makeBaseNavItem(assortment),
    hasChildren: assortment.childrenCount > 0,
  };
};

export const BRAND_BASE_NAV_ITEM = {
  href: makeHref(BRAND_ASSORTMENT_ID),
  path: BRAND_BASE_PATH,
};
export const makeBrandNavItem = (brand: GetTopBrands_topBrands_assortment) => ({
  title: brand,
  slug: brand,
  href: makeHref(brand._id),
  path: `${SHOP_PATH}${brand.texts?.slug}`,
});
