import type { ReactNode } from "react";
import { forwardRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;

  .fade-enter {
    opacity: 0.01;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  .fade-exit {
    position: absolute;
    width: 100%;
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    position: absolute;
    width: 100%;
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
`;

const LoadingWrapper = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    style?: any;
    className?: string;
    transitionKey: string;
  }
>(({ style, className, transitionKey, children }, ref) => (
  <Wrapper style={style} className={className} ref={ref}>
    <TransitionGroup>
      <CSSTransition
        key={transitionKey}
        timeout={{ enter: 300, exit: 300 }}
        classNames={"fade"}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  </Wrapper>
));

export default LoadingWrapper;
