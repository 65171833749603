import React from "react";
import styled from "styled-components";
import type { Color, Font } from "../../../config/theme";
import mediaQueries from "../../../utils/mediaQueries";
import { iff, selectColor, selectFont } from "../../../utils/themeUtils";

export type Colors = [Color, Color];

const Base = styled.div<{ font?: Font; mobileColors: Colors; colors: Colors }>`
  @keyframes loading-placeholder {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  height: 100%;
  background: ${selectColor((p) => p.mobileColors[0])};
  position: relative;
  overflow: hidden;
  opacity: 0.6;

  ${iff((p) => p.font)`
    ${selectFont((p) => p.font)}
    transform: scaleY(0.7);
    height: auto;
    border-radius: 5px;
  `}

  &::after {
    content: "";
    display: block;
    background-color: ${selectColor((p) => p.mobileColors[1])};
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translateX(0);
    animation: 1.5s loading-placeholder ease-in-out infinite;
  }

  ${mediaQueries.desktop`
    background: ${selectColor((p) => p.colors[0])};
    &::after {
      background-color: ${selectColor((p) => p.colors[1])};
    }
  `}
`;

export interface LoadingPlaceholderProps {
  style?: React.CSSProperties;
  className?: string;
  mobileColors?: Colors;
  colors?: Colors;
  font?: Font;
}

const LoadingPlaceholder: React.FC<LoadingPlaceholderProps> = ({
  style,
  className,
  font,
  mobileColors = ["sand", "disabled"],
  colors = ["sand", "disabled"],
}) => (
  <Base
    style={style}
    className={className}
    font={font}
    mobileColors={mobileColors}
    colors={colors}
  >
    {font ? <br /> : null}
  </Base>
);

export default LoadingPlaceholder;
