import React from "react";
import styled from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import { selectColor, selectFont } from "../../../utils/themeUtils";
import type { LinkButtonProps } from "../../layout/components/Button";
import { LinkButton } from "../../layout/components/Button";
import usePreventOnClickInEditor from "../hooks/usePreventOnClickInEditor";
import Inline from "./ALink";

const Button = styled(LinkButton).attrs({
  variant: "secondary",
  size: "small",
})`
  width: max-content;
`;

const ButtonMedium = styled(LinkButton).attrs({
  variant: "secondary",
  size: "medium",
})`
  width: max-content;
`;

const ButtonSlim = styled(LinkButton).attrs({
  variant: "secondary",
  size: "extraSmall",
})`
  width: max-content;
`;

const Footer = styled(Inline)`
  max-width: 100%;
  margin-bottom: 18px;
  display: inline-block;
  color: ${selectColor("white")};
  ${selectFont("footerLink")}

  &:hover {
    text-decoration: underline;
    color: ${selectColor("white")};
  }

  ${mediaQueries.desktop`
    margin-bottom: 9px;
  `}
`;

const TYPES = {
  inline: Inline,
  button: Button,
  buttonMedium: ButtonMedium,
  buttonSlim: ButtonSlim,
  footer: Footer,
};

export type StyledLinkType =
  | "inline"
  | "button"
  | "buttonMedium"
  | "buttonSlim"
  | "footer";
export const styledLinkEnum: StyledLinkType[] = [
  "inline",
  "button",
  "buttonMedium",
  "buttonSlim",
];

interface BaseProps {
  type?: StyledLinkType;
  openInNewTab?: boolean;
  ref?: React.Ref<HTMLElement>;
}

export type StyledLinkProps = BaseProps & LinkButtonProps;

const StyledLink: React.FC<StyledLinkProps> = React.forwardRef(
  ({ type = "inline", children, openInNewTab = false, ...props }, ref) => {
    const onClick = usePreventOnClickInEditor();

    const C = TYPES[type] as any;

    return (
      <C
        target={openInNewTab ? "_blank" : undefined}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        {children}
      </C>
    );
  },
);

export default StyledLink;
