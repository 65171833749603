import React from "react";
import styled from "styled-components";
import { selectFont } from "../../../utils/themeUtils";

export const H1 = styled.h1`
  ${selectFont("extraLarge")};
  &:after {
    content: ".";
    visibility: hidden;
  }
`;
export const H2 = styled.h2`
  ${selectFont("large")};
  &:after {
    content: ".";
    visibility: hidden;
  }
`;

export const H3 = styled.h3`
  ${selectFont("medium")};
  &:after {
    content: ".";
    visibility: hidden;
  }
`;

export const H4 = styled.h4`
  ${selectFont("small")};
  &:after {
    content: ".";
    visibility: hidden;
  }
`;
type HeadingTag = "h1" | "h2" | "h3" | "h4";
type ComponentDetails = {
  styledComponent: any;
  tag: HeadingTag;
};

const COMPONENTS: {
  1: ComponentDetails;
  2: ComponentDetails;
  3: ComponentDetails;
  4: ComponentDetails;
} = {
  1: { styledComponent: H1, tag: "h1" },
  2: { styledComponent: H2, tag: "h2" },
  3: { styledComponent: H3, tag: "h3" },
  4: { styledComponent: H4, tag: "h4" },
};

export interface HeadingProps {
  style?: React.CSSProperties;
  className?: string;
  level?: 1 | 2 | 3 | 4;
  id?: string;
  tag?: HeadingTag;
}

const Heading: React.FC<HeadingProps> = ({
  style,
  className,
  level = 1,
  children,
  id,
  tag = COMPONENTS[level].tag,
}) => {
  const Component = COMPONENTS[level].styledComponent;

  return (
    <Component style={style} className={className} id={id} as={tag}>
      {children}
    </Component>
  );
};

export default Heading;
