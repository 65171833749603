import React from "react";
import styled from "styled-components";

const Base = styled.svg`
  width: 21px;
  height: 21px;
`;

export interface PlusIconProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: any;
  inverted?: boolean;
}

const PlusIcon: React.FC<PlusIconProps> = ({ style, className, inverted }) => (
  <Base viewBox="0 0 21 21" style={style} className={className}>
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <circle
        stroke={inverted ? undefined : "#478392"}
        fill={inverted ? "#478392" : undefined}
        strokeWidth="1.5"
        cx="9.5"
        cy="9.5"
        r="9.5"
      />
      <path
        fill={inverted ? "#edebe2" : "#478392"}
        fillRule="nonzero"
        d="M8.837 10.364H5.922V9.06h2.915V6.072h1.319V9.06h2.922v1.304h-2.922v2.973H8.837z"
      />
    </g>
  </Base>
);

export default PlusIcon;
