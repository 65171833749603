import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";

const ALink = styled.a`
  font-weight: ${(p) => (p.href?.startsWith("#") ? "normal" : "bold")};
  text-decoration: none;
  cursor: pointer;
  color: ${selectColor("petrol")};
  &:hover {
    text-decoration: underline;
    color: ${selectColor("petrolHover")};
  }
`;

export default ALink;
